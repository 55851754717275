//最後只有幾個站有活著

const traceConfigs = {
    'm1-001': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": 21337453,
        ga: '',
    },
    'm1-002': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": 21337483,
        ga: '',
    },
    'm1-003': {
        piwik: '457451df-701e-4335-a245-47408a2c4085',
        "51la":'',
        ga: '',
    },
    'm1-004': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": 21337487,
        ga: '',
    },
    //有在用
    'm1-005': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": 21337495,
        ga: '',
    },
    'm1-006': {
        piwik: '947104b4-352e-4069-980f-a1b8f8d195a3',
        "51la": '',
        ga: '',
    },
    'm1-007': {
        piwik: '947104b4-352e-4069-980f-a1b8f8d195a3',
        "51la": '',
        ga: '',
    },
    'm1-008': {
        piwik: '947104b4-352e-4069-980f-a1b8f8d195a3',
        "51la": 20999297,
        ga: '',
    },
    'm1-009': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": 21146741,
        ga: 'UA-168189082-6',
    },
    'm1-010': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": '',
        ga: '',
    },
    'm1-011': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": '',
        ga: '',
    },
    'm1-012': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": '',
        ga: '',
    },
    //有在用
    'm1-013': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": '',
        ga: '',
    },
    //有在用
    'm1-014': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": 21337487,
        ga: '',
    },
    //有在用
    'm1-015': {
        piwik: '62a30b2c-2a47-4f6d-9e82-3464c38763bc',
        "51la": '',
        ga: '',
    },
}

export default traceConfigs;
