/*
 * @Date: 2023-04-10 12:58:17
 * @LastEditors: simLarry
 * @LastEditTime: 2023-04-11 10:45:18
 * @FilePath: \P1-M1-QRP-CODE\components\style10\banner.js
 */
import React, { useRef } from "react";
import { Carousel } from 'antd';
import { useSize } from "ahooks";
import HostConfig from '$CONFIG/Host.config';
import LazyLoad from 'react-lazyload';
import CheckImageFormat from "../CheckImageFormat";
const CMSURL = HostConfig.Config.CMSURL;

const Banner = ({ bannerList, children, fallbackImage }) => {
	const bannerRef = useRef(null);
	const size = useSize(() => document.querySelector('body')) || {};
	const bodyWidth = size.width || 0;

	return (
		<>
			<section className="banner">
				<Carousel
					ref={bannerRef}
					autoplay={true}
					autoplaySpeed={4500}
					lazyLoad={true}
					dots={false}
				>
					{bannerList && bannerList.length &&
						bannerList.map((item, index) => {
							return (
								<LazyLoad key={index}>
									<div className="img_wrap">
										<CheckImageFormat
											avifImage={CMSURL + `${bodyWidth > 1023 ? item.cmsImageUrlWebAvif : item.cmsImageUrlMobileAvif}`}
											className={'imgItem'}
											defaultImage={CMSURL + `${bodyWidth > 1023 ?
													item.cmsImageUrlWebWebp || item.cmsImageUrlWeb :
													item.cmsImageUrlMobileWebp || item.cmsImageUrlMobile}`}
											fallbackImage={bodyWidth > 1023 ? fallbackImage.desktop : fallbackImage.mobile}
										/>
									</div>
								</LazyLoad>
							)
						})
					}
				</Carousel>
				{children}
			</section>
		</>
	);
};

export default Banner;
